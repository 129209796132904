import { useRefresh } from "ra-core";
import React from 'react';
import {
  Show,
  List,
  Datagrid,
  Filter,
  SelectInput,
  DateField,
  TextField,
  TextInput,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  TopToolbar
} from 'react-admin';
import { useRecursiveTimeout } from "../../components/recursiveTimeout";
import PauseButton from "./components/PauseButton";
//import ExportNameField from "./components/ExportNameField";
import RelatedList from "../../components/RelatedList";
import FsspRequestTypeField from "../../components/FsspRequestTypeField";
import EmptyList from "../../components/EmptyList";
import StatusField from "../../components/StatusField";
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import { NumberField } from "react-admin";
import { FunctionField } from "react-admin";
import JsonErrorsField from "../../components/JsonErrorsField";

const ExportFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name||$contL" label={'Название'} alwaysOn variant={'outlined'}/>
    <TextInput source="id" label={'ID'}  variant={'outlined'}/>
    <SelectInput source="status" label={'Статус'} variant={'outlined'} choices={[
      { id: 'created', name: 'В очереди' },
      { id: 'in_progress', name: 'В процессе' },
      { id: 'completed', name: 'Выполнен' },
      { id: 'error', name: 'Ошибка' },
      { id: 'canceled', name: 'Завершается' },
      { id: 'Stopped', name: 'Остановлен' },
    ]} />

  </Filter>
);
const LinkToRelatedRequests = ({ record }: any) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/request/fssp_main',
        search: stringify({
          page: 1,
          perPage: 25,
          sort: 'id',
          order: 'DESC',
          filter: JSON.stringify({ requestExportId: record.id }),
        }),
      }}
    >
      Запросы
    </Button>
  ) : null;
};
const ListActions = () => (
  <TopToolbar>
      <ExportFilter context="button" />
  </TopToolbar>
);
export const ExportList = (props) => {
  const refresh = useRefresh()
  useRecursiveTimeout(() => refresh(), 5000);

  return (
      <List {...props} title="Экспорты" filters={<ExportFilter/>} actions={<ListActions/>}
            sort={{ field: 'id', order: 'DESC' }}
            bulkActionButtons={false} empty={<EmptyList title={'Экспорты отсутсвуют'}/>}>
        <Datagrid rowClick="show">
          <TextField source="id"/>
          <TextField source={'name'} label={'Название'}/>
          <StatusField source="status" label={'Статус'} />
          <NumberField source="total" label={'Всего запросов'}/>
          <NumberField source="countResults" label={'Всего результатов'} />
          <TextField source="progress" label={'Прогресс'}/>
          <PauseButton/>
        </Datagrid>
      </List>
  );
}
const TitleShow = ({ record } : any) => {
  return (
    <span>
      Экспорт запросов № {record.id}
    </span>
  );
};

const ExportShowActions = (props) => (
  <TopToolbar>
  </TopToolbar>
);


export const ExportShow = (props) =>(
  <Show {...props} title={<TitleShow/>} actions={<ExportShowActions/>}>
    <TabbedShowLayout>
      <Tab label="Данные экспорта">
        <TextField source="id" />
        <FsspRequestTypeField source="type" label={'Тип'}/>
        <TextField source="fileName" label={'Файл'}/>
        <StatusField source="status" label={'Статус'} />
        <NumberField source="total" label={'Всего запросов'}/>
        <NumberField source="countResults" label={'Всего результатов'} />
        <NumberField source="countErrors" label={'Ошибок'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
      </Tab>
      <Tab label="Ошибки экспорта" path="errors">
        <JsonErrorsField source={'error'} label={'Ошибки'}></JsonErrorsField>
      </Tab>
    </TabbedShowLayout>

  </Show>
);


