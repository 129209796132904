import React, { useCallback, useContext, useState, FunctionComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';
import PauseIcon from '@material-ui/icons/Pause';
import {
  useDataProvider,
  useNotify,
  ExporterContext,
  Exporter,
} from 'ra-core';
import { ButtonProps } from '@material-ui/core/Button';
import { Button } from 'react-admin'
import ResendModal from "./ResendModal";

const ResendButton: FunctionComponent<ResendButtonProps> = ({
                                                              sort,
                                                              filter = defaultFilter,
                                                              maxResults = 1000,
                                                              resource,
                                                              onClick,
                                                              basePath,
                                                              total,
                                                              cancel = false,
                                                              label = 'ra.action.refresh',
                                                              exporter: customExporter,
                                                              ...rest
                                                            }) => {
  const exporterFromContext = useContext(ExporterContext);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);


  const handleClose = () => {
    setShowDialog(false);
  }

  const handleClick = useCallback(
    event => {

      //console.log("props", rest)
      setShowDialog(true);
      return;
      /*console.log("resource")
      dataProvider
        .getOne(`request/export/sign`, rest)
        .then(
          ({ data }) => {
            console.log("get data", data)
            const link = data.url as any;
            window.location.href = link as string;
          }
        )
        .catch(error => {
          console.error(error);
          notify('ra.notification.http_error', 'warning');
        });
      if (typeof onClick === 'function') {
        onClick(event);
      }*/
    },
    [
      dataProvider,
      exporter,
      filter,
      maxResults,
      notify,
      onClick,
      resource,
      sort,
    ]
  );
  const ricon = cancel ? <PauseIcon/> : <RefreshIcon/>;

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label={label}
      >
        {ricon}
      </Button>
      <ResendModal cancel={cancel} sort={sort} filter={filter} total={total} show={showDialog} basePath={basePath} resource={resource} handleClose={handleClose}/>
    </Fragment>
  );
};

const defaultFilter = {};

const sanitizeRestProps = ({
                             basePath,
                             ...rest
                           }: Omit<ResendButtonProps,
  'sort' | 'filter' | 'maxResults' | 'resource' | 'label' | 'exporter'>) => rest;

interface Props {
  basePath?: string,
  exporter?: Exporter,
  filter?: any,
  cancel?: boolean,
  icon?: JSX.Element,
  label?: string,
  maxResults?: number,
  onClick?: (e: Event) => void,
  resource?: string,
  sort?: { field: string, order: string },
  total: number,
}

export type ResendButtonProps = Props & ButtonProps;

ResendButton.propTypes = {
  basePath: PropTypes.string,
  exporter: PropTypes.func,
  filter: PropTypes.object,
  cancel: PropTypes.bool,
  label: PropTypes.string,
  maxResults: PropTypes.number,
  total: PropTypes.number,
  resource: PropTypes.string.isRequired,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  icon: PropTypes.any,
};

export default ResendButton;
