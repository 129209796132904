import React from 'react';
import { List, Datagrid, Show, SimpleShowLayout, SelectInput, Filter, TextInput, FunctionField, DateField, TextField } from 'react-admin';
import VideoLinkField from "../../components/VideoLinkField";
import StatusField from "../../components/StatusField";
import ListActions from "../../components/ListActions";
import EmptyList from "../../components/EmptyList";
import ScreenshotLinkField from '../../components/ScreenshotLinkField';
import { NumberField } from 'react-admin';
const BrowserSessionFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="status" label={'Статус'} variant={'outlined'} choices={[
      { id: 'created', name: 'В очереди' },
      { id: 'in_progress', name: 'В процессе' },
      { id: 'completed', name: 'Выполнен' },
      { id: 'no_results', name: 'Нет результатов' },
      { id: 'error', name: 'Ошибка' },
      { id: 'wait', name: 'Ожидание' },
      { id: 'reuse', name: 'Перезапуск браузера' },
      { id: 'time_out', name: 'Тайм аут сессии браузера' },
      { id: 'system_overloaded', name: 'Сайт перегружен' },
      { id: 'anomaly_traffic', name: 'Аномальная активность' },
      { id: 'error_captcha', name: 'Ошибка распознавания капчи' },
      { id: 'error_proxy', name: 'Ошибка прокси' },
      { id: 'invalid_session', name: 'Ошибка сессии' },
      { id: 'bad_login', name: 'Не верный логин-пароль' },
    ]} />
    <TextInput source="id" label={'id'} variant={'outlined'}/>
  </Filter>
);
export const BrowserSessionList = (props) => (
  <List {...props} title="Сессии"    filters={<BrowserSessionFilter />} actions={<ListActions showCreate={false}/>}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}   empty={<EmptyList title={'Пока сессий не было'} description={'Для того чтобы начать работать импортируйте или создайте запрос'} showButton={false}/>}>
    <Datagrid    rowClick="show">
      <TextField source="id" />
      <StatusField source="status" label={'Статус'} />
      <DateField source={'createdAt'} label={'Создан'} showTime/>
      <DateField source={'startAt'} label={'Старт'} showTime/>
      <DateField source={'finishAt'} label={'Финиш'} showTime/>
      <FunctionField label="Прогресс" render={record => `${record.currentStep ? Math.ceil((record.currentStep / record.totalSteps) * 100) + '%' : ''}`} />
      <TextField source={'currentStepName'} label={'Шаг'}/>
      <NumberField source={'countTotal'} label={'Всего найдено'}/>
      <NumberField source={'countAdded'} label={'Новых'}/>
      <NumberField source={'countUpdated'} label={'Обновлено'}/>
      <ScreenshotLinkField source={"screenshot"} label={'Скриншот'} addLabel={true}/>
      <VideoLinkField source="video" label={'Видео'} />
    </Datagrid>
  </List>
);

const Title = ({ record } : any) => {
  return (
    <span>
      Сессия № {record.id}
    </span>
  );
};

export const BrowserSessionShow = (props) => (
  <Show {...props} title={<Title />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <StatusField source="status" label={'Статус'} />
      <DateField source={'createdAt'} label={'Создан'} showTime/>
      <DateField source={'startAt'} label={'Старт'} showTime/>
      <DateField source={'finishAt'} label={'Финиш'} showTime/>
      <FunctionField label="Прогресс" render={record => `${record.currentStep ? Math.ceil((record.currentStep / record.totalSteps) * 100) + '%' : ''}`} />
      <TextField source={'currentStepName'} label={'Шаг'}/>
      <NumberField source={'countTotal'} label={'Всего найдено'}/>
      <NumberField source={'countAdded'} label={'Новых'}/>
      <NumberField source={'countUpdated'} label={'Обновлено'}/>
      <ScreenshotLinkField source={"screenshot"} label={'Скриншот'} addLabel={true}/>
      <VideoLinkField source="video" label={'Видео'} />
      <TextField source={'error'} label={'Ошибка'}/>
      <TextField source={'sessionId'} label={'ID сессии'}/>
    </SimpleShowLayout>
  </Show>
);
