import React, { Children, isValidElement, cloneElement, Fragment, useState } from 'react';
import {
    ReferenceInput,
    List,
    Datagrid,
    Edit,
    Show,
    SelectInput,
    BooleanInput,
    Filter,
    ReferenceField,
    TextInput,
    FunctionField,
    Create,
    SimpleForm,
    DateField,
    TextField,
    TabbedShowLayout,
    Labeled,
    Pagination,
    TopToolbar, CreateButton,
    Tab,
    sanitizeListRestProps,
    ReferenceManyField,
    useDataProvider,
    useNotify
} from 'react-admin';
import DateRangeInput from '../../components/DateRangeInput'
import { Typography } from '@material-ui/core'
import classnames from 'classnames';
import { Grid } from '@material-ui/core'
import BooleanField from "../../components/BooleanField";
import ScreenshotImageField from "../../components/ScreenshotImageField";
import ScreenshotLinkField from '../../components/ScreenshotLinkField';
import RelatedList from "../../components/RelatedList";
import VideoPlayerField from "../../components/VideoPlayerField";
import ResendButton from "./components/ResendButton";
import ExportButton from "./components/ExportButton";
import ResendManyButton from "./components/ResendManyButton";
import VideoLinkField from "../../components/VideoLinkField";
import StatusField from "../../components/StatusField";
import EmptyList from "../../components/EmptyList";
import FsspFormErrorsField from "../../components/FsspFormErrorsField";
import IdShowButtonField from "../../components/IdShowButtonField";
import { useRecursiveTimeout } from '../../components/recursiveTimeout';
import { SelectArrayInput } from 'react-admin';
import { NumberInput } from 'react-admin';
import { DateInput } from 'react-admin';
import { NumberField } from 'react-admin';
import BrowserSessionTypeField from '../../components/BrowserSessionTypeField';
import { feedStatusesMap, feedTypesMap } from '../Result';
import { required } from 'react-admin';
import { usePermissions } from 'react-admin';
import { DeleteButton } from 'react-admin';
import { EditButton } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

// style list
const useStyles = makeStyles((theme) => ({
    selArrayInput: {
      minWidth: "100px",
    }
  }));

const RequestNotifyFilter = (props) => {
    const classes = useStyles();
    return (
    <Filter {...props}>
        <SelectArrayInput className={classes.selArrayInput} alwaysOn={true} emptyText={'Не применять'} options={{ fullWidth: true }} allowEmpty={true} source="status||$in" label={'Статус'} variant={'outlined'}
                     choices={[
                         { id: 'created', name: 'Создан' },
                         { id: 'in_queue', name: 'В очереди' },
                         { id: 'in_progress', name: 'В процессе' },
                         { id: 'completed', name: 'Исполнен' },
                         { id: 'error', name: 'Ошибка' },
                         { id: 'canceled', name: 'Остановлен' },
                     ]}/>
        <SelectInput alwaysOn={true} emptyText={'Не важно'} allowEmpty={true} source="hasResults"
                     label={'Есть Результаты'} variant={'outlined'} choices={[
            { id: 'true', name: 'Есть результаты' },
            { id: 'false', name: 'Нет результатов' }
        ]}/>
        <ReferenceInput label="Аккаунт ЕПГУ" options={{ fullWidth: true }} source="senderAccountId" reference="sender-account" variant={'outlined'}>
            <SelectInput optionText={(record) => `${record.role}, ${record.name}${record.comment ? (' ('+record.comment+')') : ''}`} />
        </ReferenceInput>
        <TextInput source="feedQuery||$contL" label={'Поисковый запрос'} variant={'outlined'}/>
        <DateRangeInput source="endProcessAt" sourceStart={'endProcessAt||gte'} sourceEnd={'endProcessAt||lte'} label={'Дата выполнения'}/>
    </Filter>
);
}


const RequestBulkActionButtons = props => (
    <Fragment>
        <ExportButton {...props}    />
        <ResendManyButton label="Остановить" {...props} cancel={true}/>
        <ResendManyButton label="Переотправить" {...props} />
    </Fragment>
);
const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         filterValues,
                         permanentFilter,
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         showCreate,
                         ...rest
                     }: any) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {showCreate ? <CreateButton basePath={basePath}/> : null}
        <ExportButton disabled={total === 0}
                      label={'Экспорт Все'}
                      resource={resource}
                      sort={currentSort}
                      filter={{ ...filterValues, ...permanentFilter }}
                      total={total}
                      {...rest}    />
        <ResendButton disabled={total === 0}
                  cancel={true}
                  label={'Остановить Все'}
                  resource={resource}
                  sort={currentSort}
                  filter={{ ...filterValues, ...permanentFilter }}
                  total={total}
                  {...rest}    />
        <ResendButton disabled={total === 0}
                      label={'Перезапустить Все'}
                      resource={resource}
                      sort={currentSort}
                      filter={{ ...filterValues, ...permanentFilter }}
                      total={total}
                      {...rest}    />
    </TopToolbar>
);

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
    showCreate: true
};

export const RequestNotifyList = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [count, setCount] = useState(0);
    const getSystemOverloadStatus = async () => {
        setCount(count+1);
        const {data, isLoading, eror} = await dataProvider.getOne('browser-session/last', { id: 'status'});
        console.log(data);
        if (data.totalCount > 5 && data.systemOverloadCount > (data.threshold !== undefined ? data.threshold : data.totalCount * 0.5)) {
            const status =  (data.systemOverloadCount >= data.totalCount * 0.9 ? 'На сайте ЕПГУ технические работы, отправка возобновится после их окончания' : `Сайт ФССП перегружен, скорость отправки снижена${data.isAdmin ? (' ('+data.systemOverloadCount +'/'+ data.totalCount +')') : ''}`);
            if (!isLoading)
                notify(status, { type: 'warning' });
            //return status;
        }
    }
    const onError = (error) => {
        notify(`Could not load list: ${error.message}`, { type: 'warning' });
    };
    useRecursiveTimeout(() => getSystemOverloadStatus(), 12000);
    if (!count) { getSystemOverloadStatus(); }
    return (<List {...props} title={'Запросы поиска'} filters={<RequestNotifyFilter/>}
          sort={{ field: 'id', order: 'DESC' }}
          pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}></Pagination>}
          actions={<ListActions showCreate={true}/>}
          bulkActionButtons={<RequestBulkActionButtons/>}
          empty={<EmptyList title={'Запросы отсутствуют'}
                            description={'Для того чтобы начать работать добавьте запрос'}
                            buttonText={'Добавить запрос'}/>}
          queryOptions={{ onError }}>
        <Datagrid>
            <IdShowButtonField label={'Id'}/>
            <StatusField source="status" label={'Статус выполнения'}/>
            <BooleanField source="countResults" label={'Есть результаты'}/>
            <NumberField source="lastFeedId" label={'Последний номер уведомления'}/>
            <FunctionField label="Типы уведомлений"
                    render={record => Array.isArray(record.feedTypes)
                        ? record.feedTypes.reduce((accumulator, currentValue) => accumulator + `${feedTypesMap[currentValue] || currentValue},`, '')
                        : `${feedTypesMap[record.feedTypes] || record.feedTypes}`
                    }/>
            <FunctionField label="Статус уведомления"
                    render={record => `${feedStatusesMap[record.feedStatus] || record.feedStatus}`}/>
            <TextField source="feedQuery" label={'Поисковый запрос'}/>
            <NumberField source="feedStarId" label={'Стартовый номер уведомления'}/>
            <DateField label="Дата и время начала поиска" source="feedStartDate" defaultValue={new Date()} variant={'outlined'}/>
            <DateField label="Дата и время завершения поиска" source="feedEndDate" variant={'outlined'}/>
            <DateField source={'endProcessAt'} label={'Дата последней проверки'} showTime/>
        </Datagrid>
    </List>);
}

const TitleShow = ({ record }: any) => {
    return (
        <span>
      Запрос поиска № {record.id}
    </span>
    );
};
const SanitizedGrid = ({ ...props }) => {
    console.log("props.children", props.children)
    const newProps = {
        record: (props as any).record,
        resource: (props as any).resource,
        basePath: (props as any).basePath,
    };
    return (
        <Grid item={props.item} md={props.md} container={props.container}>
            {Children.map(props.children, field =>
                field && isValidElement(field) ? (
                    ['Box', 'SanitizedGrid'].indexOf((field.type as any).name) > -1 ? cloneElement(field, { ...props, ...(field.props as any) }) :
                        <div
                            key={(field.props as any).source}
                            className={classnames(
                                `ra-field ra-field-${(field.props as any).source}`,
                                (field.props as any).className
                            )}
                        >
                            {(field.props as any).addLabel ? (
                                <Labeled
                                    record={props.record}
                                    resource={props.resource}
                                    basePath={props.basePath}
                                    label={(field.props as any).label}
                                    source={(field.props as any).source}
                                    disabled={false}
                                >
                                    {field}
                                </Labeled>
                            ) : typeof field.type === 'string' ? (
                                field
                            ) : (
                                cloneElement(field, newProps)
                            )}
                        </div>
                ) : null
            )}
        </Grid>
    );
};

const RequestShowActions = ({ data } : any) => {
    const { permissions } = usePermissions();
    return (
      <TopToolbar>
        <EditButton record={data} mutationMode="pessimistic" redirect={false}/>
        {(permissions !== 'super_admin') ? null : <DeleteButton label={''}  record={data} mutationMode="pessimistic" redirect={false}/>}
      </TopToolbar>
    );
};

export const RequestNotifyShow = (props) => (
    <Show {...props} title={<TitleShow/>} actions={<RequestShowActions/>}>

        <TabbedShowLayout>
            <Tab label="Запрос">

                <Typography variant="subtitle1" gutterBottom>
                    Информация о поисковом запросе
                </Typography>
                <SanitizedGrid container spacing={4}>
                    <StatusField source="status" label={'Статус запроса'} addLabel={true}/>
                    <SanitizedGrid container={false} item md={4}>
                        <ReferenceField label="Подано от имени" source="senderAccountId" reference="sender-account" link={'show'}>
                            <FunctionField source="name" render={i => `${i.name} (${i.login})`}/>
                        </ReferenceField>
                        <TextField source="countResults" label={'Количество результатов'}/>
                        <TextField source="countErrors" label={'Количество ошибок'}/>
                        <DateField source={'endProcessAt'} label={'Дата последней проверки'} showTime/>
                        <NumberField source="lastFeedId" label={'Последний номер уведомления'}/>
                    </SanitizedGrid>
                    <SanitizedGrid container={false} item md={4}>
                        <FunctionField label="Типы уведомлений"
                                render={record => Array.isArray(record.feedTypes)
                                    ? record.feedTypes.reduce((accumulator, currentValue) => accumulator + `${feedTypesMap[currentValue] || currentValue},`, '')
                                    : `${feedTypesMap[record.feedTypes] || record.feedTypes}`
                                }/>
                        <FunctionField label="Статус уведомления"
                                render={record => `${feedStatusesMap[record.feedStatus] || record.feedStatus}`}/>
                        <TextField source="feedQuery" label={'Поисковый запрос'}/>
                        <NumberField source="feedStarId" label={'Стартовый номер уведомления'}/>
                        <DateField label="Дата и время начала поиска" source="feedStartDate" defaultValue={new Date()} variant={'outlined'}/>
                        <DateField label="Дата и время завершения поиска" source="feedEndDate" variant={'outlined'}/>
                        <NumberField source="recreateCycle" label={'Цикл перезапуска в минутах (0 - не перезапускать)'} variant={'outlined'}/>
                    </SanitizedGrid>

                </SanitizedGrid>


            </Tab>


            <Tab label="Результаты" path="results">
                <ReferenceManyField reference="request-result" target="requestId" addLabel={false}>
                    <RelatedList
                        hasCreate={false}
                        emptyTitle={'Результаты отсутствуют'}
                    >
                        <DateField source='answerAt' label={'Дата проверки'} showTime/>
                        <DateField source='date' label={'Дата уведомления'} showTime/>
                        <FunctionField label="Тип уведомления" render={record => `${feedTypesMap[record.type] || record.type}`}/>
                        <FunctionField label="Статус уведомления" render={record => `${feedStatusesMap[record.status] || record.status}`}/>
                        <TextField source="extId" label={'Внешний идентификатор уведомления'}/>
                        <NumberField source="innerId" label={'Внутренний идентификатор уведомления'}/>
                        <TextField source="title" label={'Заголовок уведомления'}/>
                        <TextField source="subTitle" label={'Подзаголовок уведомления'}/>
                        <BooleanField source="answerHasFiles" label={'Есть вложенные файлы'}/>
                        <NumberField source="ownerId" label={'ID владельца'}/>
                    </RelatedList>

                </ReferenceManyField>
            </Tab>
            <Tab label="Ошибки" path="errors">


                <StatusField source="status" label={'Статус подачи'} addLabel={true}/>
                <TextField source="remoteStatus" label={'Статус ЕПГУ'}/>

                <FunctionField label="Прогресс"
                               render={record => `${record.browserSession && record.browserSession.currentStep ? Math.ceil((record.browserSession.currentStep / record.browserSession.totalSteps) * 100) + '%' : ''}`}/>

                <FsspFormErrorsField source={'formErrors'} label={'Ошибки'}></FsspFormErrorsField>
                <ScreenshotImageField source="browserSession.screenshot" label={'Скриншот ошибки'}/>
                <VideoLinkField source="browserSession.video" label={'Видео'} addLabel={true}/>
                <VideoPlayerField source="browserSession.video" label={'Видео'}/>
            </Tab>

            <Tab label="Сессии">
                <ReferenceManyField reference="browser-session" target="requestId" addLabel={false}>
                <RelatedList rowClick={'show'}
                            hasCreate={false}
                            emptyTitle={'Сессии отсутствуют'}
                            sort={{ field: 'id', order: 'DESC' }}
                >

                    <TextField source="id"/>
                    <BrowserSessionTypeField source="requestType" label={'Тип запроса'}/>
                    <StatusField source="status" label={'Статус'}/>
                    <DateField source={'createdAt'} label={'Создан'} showTime/>
                    <DateField source={'startAt'} label={'Старт'} showTime/>
                    <DateField source={'finishAt'} label={'Финиш'} showTime/>
                    <FunctionField label="Прогресс"
                                render={record => `${record.currentStep ? Math.ceil((record.currentStep / record.totalSteps) * 100) + '%' : ''}`}/>
                    <TextField source={'currentStepName'} label={'Шаг'}/>
                    <ScreenshotLinkField source={"screenshot"} label={'Скриншот'} addLabel={true}/>
                    <VideoLinkField source={"video"} label={'Видео'} addLabel={true}/>
                </RelatedList>

                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

//const feedTypesList = Object.entries(feedTypesMap).map(e => {return {id: e[0], name: e[1]};})

export const RequestNotifyEdit = (props) => (
    <Edit title={<TitleShow/>} {...props} >
        <SimpleForm redirect={'show'}>
            <SelectArrayInput source="feedTypes" label={'Типы уведомлений'} variant={'outlined'} fullWidth={true} choices={Object.entries(feedTypesMap).map(e => {return {id: e[0], name: e[1]};})}/>
            <SelectInput source="feedStatus" label={'Статус уведомления'} variant={'outlined'} fullWidth={true} choices={Object.entries(feedStatusesMap).map(e => {return {id: e[0], name: e[1]};})}/>
            <TextInput source="feedQuery" label={'Поисковый запрос'} variant={'outlined'} fullWidth={true}/>
            <NumberInput source="feedStarId" label={'Стартовый номер уведомления'} variant={'outlined'} fullWidth={true}/>
            <DateInput label="Дата и время начала поиска" source="feedStartDate" defaultValue={new Date()} variant={'outlined'} fullWidth={true}/>
            <DateInput label="Дата и время завершения поиска" source="feedEndDate" variant={'outlined'} fullWidth={true}/>
            <NumberInput source="recreateCycle" label={'Цикл перезапуска в минутах (0 - не перезапускать)'} variant={'outlined'} fullWidth={true}/>
            <BooleanInput label="Включить видео" source="enableVideo"/>
        </SimpleForm>
    </Edit>
);

export const RequestNotifyCreate = (props) => (
    <Create title="Создать запрос поиска" {...props} >
        <SimpleForm redirect={'list'}>
            <SelectArrayInput source="feedTypes" label={'Типы уведомлений'} variant={'outlined'} fullWidth={true} choices={Object.entries(feedTypesMap).map(e => {return {id: e[0], name: e[1]};})}/>
            <SelectInput source="feedStatus" label={'Статус уведомления'} variant={'outlined'} fullWidth={true} choices={Object.entries(feedStatusesMap).map(e => {return {id: e[0], name: e[1]};})}/>
            <TextInput source="feedQuery" label={'Поисковый запрос'} variant={'outlined'} fullWidth={true}/>
            <NumberInput source="feedStarId" label={'Стартовый номер уведомления'} variant={'outlined'} fullWidth={true}/>
            <DateInput label="Дата и время начала поиска" source="feedStartDate" defaultValue={new Date()} variant={'outlined'} fullWidth={true}/>
            <DateInput label="Дата и время завершения поиска" source="feedEndDate" variant={'outlined'} fullWidth={true}/>
            <NumberInput source="recreateCycle" label={'Цикл перезапуска в минутах (0 - не перезапускать)'} variant={'outlined'} fullWidth={true}/>
            <ReferenceInput label="Аккаунт ЕПГУ" source="senderAccountId" reference="sender-account" variant={'outlined'} fullWidth={true} validate={required()}>
                <SelectInput optionText={(record) => `${record.role}, ${record.name}${record.comment ? (' ('+record.comment+')') : ''}`} />
            </ReferenceInput>
            <BooleanInput label="Включить видео" source="enableVideo"/>
        </SimpleForm>
    </Create>
);
