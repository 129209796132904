import React from 'react'
import {
  usePermissions,
  useLogout,
  BooleanInput,
  BooleanField,
  SaveButton,
  Toolbar,
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  PasswordInput
} from 'react-admin'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
import RoleField from '../../components/RoleField'
import DisableOnIcon from '@material-ui/icons/Clear';
import DisableOffIcon from '@material-ui/icons/Done';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie'
import { Redirect } from 'react-router';

const UserFiler = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'}/>
    <TextInput source="login||$startsL&login||$startsL&login||$startsL" label={'Поиск'} variant={'outlined'} alwaysOn={true} resettable={true}/>
    <TextInput source="name" label={'Имя'} variant={'outlined'}/>
    <SelectInput
      source="role"
      label={'Position'}
      variant={'outlined'}
      choices={[
        { id: 'operator', name: 'Оператор' },
        { id: 'manager', name: 'Менеджер' },
        { id: 'admin', name: 'Администратор' },
      ]}
    />

  </Filter>
)

export const UserList = (props) => (
  <List
    {...props}
    title="Пользователи"
    filters={<UserFiler/>}

    sort={{ field: 'login', order: 'ASC' }}
    bulkActionButtons={false}
   >

    <Datagrid rowClick={'edit'}>

      <TextField source="id" label={'ID'}/>
      <RoleField source="role" label={'Роль'}/>
      <TextField source="login" label={'Логин'}/>
      <TextField source="name" label={'Имя'}/>
      <BooleanField source="isDisable" label={'Доступ разрешен'} valueLabelTrue='Нет' valueLabelFalse='Да' TrueIcon={DisableOnIcon} FalseIcon={DisableOffIcon} looseValue={true}/>
    
    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Пользователь: {record?.login}
    </span>
  )
}

const PasswordPolicy = props => (
  <Box m={2} top={0} marginTop={0}>
    <Typography variant={'body2'} color={'error'} >Требования к паролю:</Typography>
    <Typography variant={'body2'} color={'error'} > - минимальная длина 6 символов;</Typography>
    <Typography variant={'body2'} color={'error'} > - содержит строчные и прописные символы латинского алфавита;</Typography>
    <Typography variant={'body2'} color={'error'} > - содержит минимум одну цифру;</Typography>
    <Typography variant={'body2'} color={'error'} >{" - содержит минимум один спец. символ: !\"#$%&'()*+,-./:;<=>?@[]^_\`{|}~;"}</Typography>
    <Typography variant={'body2'} color={'error'} > - не совпадает с ранее установленными.</Typography>
  </Box>
)

const UserEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

export const UserEdit = (props) => {
    const {permissions} = usePermissions();
    const {id} = props;
    const getUserId = () => {
        const {id} = jwt_decode(Cookies.get('auth-token')) as any;
        return id.toString();
    }
    const notify = useNotify();
    const onError = (error) => {
        notify(`Не удается изменить: ${error.message}`, { type: 'warning' });
    };
    return (
        <Edit {...props} title={<TitleEdit/>} undoable={false} onFailure={onError}>
            <SimpleForm redirect={'list'} toolbar={permissions === 'super_admin' ? <Toolbar/> : <UserEditToolbar/>}>
                <TextInput source="name" label={'ФИО'} variant={'outlined'} fullWidth={true} validate={required()}/>
                <TextInput source="login" format={v => v.toLowerCase()} label={'Логин'} variant={'outlined'} fullWidth={true} validate={required()}/>
                <SelectInput
                    source="role"
                    label={'Роль'}
                    fullWidth={true}
                    variant={'outlined'}
                    choices={[
                        {id: 'operator', name: 'Оператор'},
                        {id: 'manager', name: 'Менеджер'},
                        {id: 'admin', name: 'Администратор'},
                    ]}
                />
                <PasswordInput source="setPassword" label={'Пароль'} variant={'outlined'} fullWidth={true}/>
                <PasswordPolicy />
                {getUserId() === id ? null :
                    <BooleanInput source="isDisable" label={'Блокировать учетную запись'} fullWidth={true}/>}
            </SimpleForm>
        </Edit>
    );
}

export const UserEditPassword = (props) => {
    const notify = useNotify();
    const logout = useLogout();
    const onError = (error) => {
        notify(`Не удается сохранить: ${error.message}`, { type: 'warning' });
    };
    const onSuccess = (values) => {
        notify(`Пароль изменен!`)
        logout();
    }
    return (
        <Edit {...props} title={<TitleEdit/>} onFailure={onError} onSuccess = {onSuccess} undoable={false}  redirect={'/login'}>
            <SimpleForm toolbar={<UserEditToolbar />}>
                <TextInput source="name" label={'ФИО'} variant={'outlined'} fullWidth={true} validate={required()}/>
                <PasswordInput source="setPassword" label={'Пароль'} variant={'outlined'} fullWidth={true} validate={required()}/>
                <PasswordPolicy />
            </SimpleForm>
        </Edit>
    );
}

export const UserPassList = (props) => {
  const redirectEditUser = () => {
    const {id} = jwt_decode(Cookies.get('auth-token')) as any;
    return <Redirect to={"/user/"+id.toString()} />
  }
  return (<List
      {...props}
      title="Пользователи"
      empty={redirectEditUser()} 
      >
      <Typography>Нет доступа</Typography>
    </List>
  )
}

export const UserCreate = (props) => {
  const notify = useNotify();
  const {permissions} = usePermissions();
  const onError = (error) => {
    notify(`Не удается создать: ${error.message}`, { type: 'warning' });
  };
  return (
    <Create title="Создать пользователя" {...props} onFailure={onError}>
      <SimpleForm redirect={'list'}>
        <TextInput source="name" label={'ФИО'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <TextInput source="login" format={v => v?.toLowerCase()} label={'Логин'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <SelectInput
          source="role"
          label={'Роль'}
          fullWidth={true}
          variant={'outlined'}
          choices={[
            { id: 'operator', name: 'Оператор' },
            { id: 'manager', name: 'Менеджер' },
            { id: 'admin', name: 'Администратор' },
          ]}
        />
        <PasswordInput source="setPassword" label={'Пароль'} variant={'outlined'} fullWidth={true} validate={required()}/>
        <PasswordPolicy />
        { permissions !== 'super_admin' ? null : <TextInput source="accountName" label={'Название компании (группы)'} variant={'outlined'} fullWidth={true}/> }
      </SimpleForm>
    </Create>
  )
}
