import React from 'react'
import { Admin, Resource } from 'react-admin'
import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import MyLayout from "./layout/MyLayout";
//import { CustomTypeCreate, CustomTypeEdit, CustomTypeList } from "./Resources/CustomType";
import { dataProvider } from "./common/dataProvider";
import { ProxyCreate, ProxyEdit, ProxyList } from "./Resources/proxy";
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {UserCreate, UserEdit, UserEditPassword, UserList, UserPassList} from "./Resources/users";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import './index.css'
import { BrowserSessionShow } from "./Resources/BrowserSession";
import { ExportList, ExportShow } from './Resources/Export'
import GetAppIcon from '@material-ui/icons/GetApp';
import {
  RequestNotifyCreate,
  RequestNotifyEdit,
  RequestNotifyList,
  RequestNotifyShow
} from "./Resources/RequestNotify";
import LoginPage from "./Resources/Login";
import { AuthProvider } from "./common/AuthProvider";
import { createBrowserHistory as createHistory } from 'history';
import { theme } from './style'
import { ResultShow, ResultList } from './Resources/Result'
import { SenderAccounCreate, SenderAccountEdit, SenderAccountList } from './Resources/SenderAccount';

/*const dataProvider = fakeDataProvider({
  'browser-session': [
    { id: 0, title: 'Hello, world!' },
    { id: 1, title: 'FooBar' },
  ],
  'import': [
    { id: 0, title: 'Hello, world!' },
    { id: 1, title: 'FooBar' },
  ]
})
*/
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')


const history = createHistory();
const App = () => {
  return (
    <Admin
      history={history}
      theme={theme}
      layout={MyLayout}
      loginPage={LoginPage}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      requireAuth
    >
      {permissions => {
        if (permissions === '')
          return [
            <Resource
                path={'user'}
                name="user"
                edit={UserEditPassword}
                list={UserPassList}
                options={{ label: 'Пользователи' }}
            />,
          ]
        return [


      <Resource
        path={'request/fssp_main'}
        name="request/fssp_main"
        list={RequestNotifyList}
        show={RequestNotifyShow}
        edit={RequestNotifyEdit}
        create={RequestNotifyCreate}
        options={{ label: 'Запросы' }}
      />,
      <Resource
        path={'browser-session'}
        name="browser-session"
        show={BrowserSessionShow}
      />,
      <Resource
        path={'request-result'}
        name="request-result"
        list={ResultList}
        show={ResultShow}
        options={{ label: 'Уведомления' }}
      />,

      <Resource
        path={'export'}
        name="export"
        list={ExportList}
        show={ExportShow}
        options={{ label: 'Экспорты' }}
        icon={GetAppIcon}
      />,

      ...((permissions === 'admin' || permissions === 'super_admin') ? [
      <Resource
        path={'sender-account'}
        name="sender-account"
        list={SenderAccountList}
        create={SenderAccounCreate}
        edit={SenderAccountEdit}
        options={{ label: 'Аккаунты ЕПГУ' }}
        icon={VpnKeyIcon}
      />,
      <Resource
        path={'user'}
        name="user"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={EmojiPeopleIcon}
        options={{ label: 'Пользователи' }}
      />,
      <Resource
        path={'proxy-import'}
        name="'proxy-import"
      />,

      <Resource
        path={'proxy'}
        name="proxy"
        list={ProxyList}
        create={ProxyCreate}
        edit={ProxyEdit}
        icon={SettingsEthernetIcon}
        options={{ label: 'Прокси' }}

      />
      
    ] : [])
  ];
}
  }
    </Admin>
  );
};

export default App
