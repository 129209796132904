import React, { FC, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  PropTypes as MuiPropTypes,
} from '@material-ui/core';
import { useDataProvider, useNotify } from 'ra-core';
import { FormWithRedirect, } from 'ra-core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  useRefresh,
  Button,
  SaveButton,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";

import PauseIcon from '@material-ui/icons/Pause';
const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  gridItem: {
    width: '50%'
  }
}))

interface Props {
  basePath,
  record,
  show,
  handleClose,
}


const PauseModal: FC<Props> = ({
                                 basePath = '',
                                 record,
    show,
                                 handleClose,
                                 ...rest
                                                }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  //const history = useHistory();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const handleCloseClick = () => {
    handleClose();
  };


  const handleSubmit = (values) => {

    setSaving(true);
    dataProvider
      .update(`export`, {
       id: record.id, data: {status: 'canceled'}, previousData: record,
      })
      .then(
        ({ data }) => {
          console.log("get data", data)
          const link = data as any;

          setSaving(false);
          refresh();
          handleClose();
        }
      )
      .catch(error => {
        console.error(error);
        setSaving(false);
        notify('ra.notification.http_error', 'warning');
      });

  }


  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label="Экспорт"
    >
      <DialogTitle>Экспорт</DialogTitle>
      <FormWithRedirect save={handleSubmit} version={1000} saving={false} redirect={''} onSubmit={null} render={ ({handleSubmit}) => (<Fragment>
        <DialogContent>

          <Fragment>
            <Box  p={2}><Typography>Вы уверены, что хотите остановить экспорт?</Typography>
            </Box>
          </Fragment>


        </DialogContent>
        <DialogActions>
          <SaveButton
            handleSubmitWithRedirect={
              handleSubmit
            }
            label={'Пауза'}
            icon={<PauseIcon/>}
            saving={saving}

          />
          <Button label="ra.action.cancel" onClick={handleClose}>
            <IconCancel />
          </Button>
        </DialogActions>
        </Fragment>)}/>
    </Dialog>
);
}


PauseModal.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.any,
  handleClose: PropTypes.func,

};

export default PauseModal;
