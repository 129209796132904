import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
const labels = {
'fssp_main': 'Поиск ФЛ' ,
'physical': 'Поиск ФЛ',
'legal': 'Поиск ЮЛ',
'ip': 'Поиск по ИП',
'id': 'Поиск по ИД',
'inn': 'Поиск по ИНН ЮЛ',
}
const FsspRequestTypeField = ({
                          source, record = {}
                        }) =>
  <Typography variant={'body2'} >{labels[record[source]] || ''}</Typography>;

FsspRequestTypeField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default FsspRequestTypeField;
