import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const StatusField = ({
                          source, record = {}, labels = {
    'created': 'Создан',
    'in_queue': 'В очереди',
    'in_progress': 'В процессе',
    'no_results': 'Не найдено',
    'system_overloaded': 'Сайт перегружен',
    'error_captcha': 'Ошибка распознавания капчи',
    'finished': 'Выполнен',
    'completed': 'Исполнен',
    'error': 'Ошибка',
    'canceled': 'Остановлен',
    'stopped': 'Завершен принуд.',
    'wait': 'Ожидание',
    'reuse': 'Перезапуск браузера',
    'time_out': 'Тайм аут сессии браузера',
    'anomaly_traffic': 'Аномальная активность',
    'error_proxy': 'Ошибка прокси',
    'invalid_session': 'Ошибка сессии',
    'bad_login': 'Не верный логин-пароль',
  }, colors = {
    'created': 'success',
    'in_progress': 'warning',
    'no_results': 'warning',
    'in_queue': 'warning',
    'finished': 'success',
    'completed': 'success',
    'error': 'error',
    'system_overloaded': 'error',
    'error_captcha': 'error',
    'canceled': 'warning',
    'stopped': 'error',
    'wait': 'Ожидание',
    'reuse': 'warning',
    'time_out': 'warning',
    'anomaly_traffic': 'warning',
    'error_proxy': 'error',
    'invalid_session': 'error',
    'bad_login': 'error',
  } }: {labels?: any, source?: any, record?: any, colors?: any}) =>
  <Typography variant={'body2'} color={colors[record[source]] || ''}>{labels[record[source]] || ''}</Typography>;

StatusField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default StatusField;
