import React, { cloneElement, Fragment } from 'react';
import {
    ReferenceInput,
    List,
    Datagrid,
    Show,
    SelectInput,
    SimpleShowLayout,
    Filter,
    TextInput,
    FunctionField,
    DateField,
    TextField,
    required,
    Pagination,
    TopToolbar, CreateButton,
    sanitizeListRestProps, BooleanField
} from 'react-admin';
import DateRangeInput from '../../components/DateRangeInput';
import ExportButton from "./components/ExportButton";
import EmptyList from "../../components/EmptyList";
import IdShowButtonField from "../../components/IdShowButtonField";
import { NumberField } from 'react-admin';
import FsspAnswerFilesField from '../../components/FsspAnswerFilersField';
import FsspAnswerHtmlField from '../../components/FsspAnswerHtmlField';
import FsspAnswerField from '../../components/FsspAnswerField';
import { SelectArrayInput } from 'react-admin';

export const feedTypesMap = {
    'ORDER': 'Заявление',
    'COMPLEX_ORDER': 'Составное заявление',
    'EQUEUE': 'Электронная очередь',
    'APPEAL': 'Обращение',
    'KND_APPEAL_DRAFT': 'Черновик обращения',
    'CLAIM': 'Требование',
    'SIGN': 'Подпись',
    'GEPS': 'Госпочта',
    'DRAFT': 'Черновик',
    'ESIGNATURE': 'Системные',
    'INFO': 'Новости'
};
export const feedStatusesMap = {
    'inbox': 'Почтовое уведомление',
    'executed': 'Исполнено',
    'edit': 'Черновик заявления',
    'reject': 'Отказано в предоставлении услуги',
    'done': 'Заявление зарегистрировано',
};

const RequestNotifyFilter = (props) => (
    <Filter {...props}>
        <TextInput source="extId" alwaysOn={true} label={'Внешний идентификатор'} variant={'outlined'}/>
        <DateRangeInput source="answerAt" sourceStart={'answerAt||gte'} sourceEnd={'answerAt||lte'} label={'Дата проверки'}/>
        <TextInput source="request.feedQuery||$contL" label={'Поисковый запрос'} alwaysOn={true} variant={'outlined'}/>
        <TextInput source="request.id" label={'ID запроса'} variant={'outlined'}/>
        <SelectArrayInput source="feedTypes||$cont " label={'Типы уведомлений'} variant={'outlined'} fullWidth={true} choices={Object.entries(feedTypesMap).map(e => {return {id: e[0], name: e[1]};})}/>
        <SelectInput source="feedStatus" label={'Статус уведомления'} variant={'outlined'} fullWidth={true} choices={Object.entries(feedStatusesMap).map(e => {return {id: e[0], name: e[1]};})}/>
        <ReferenceInput label="Аккаунт ЕПГУ" source={'request.senderAccountId'} reference="sender-account" variant={'outlined'} fullWidth={true} validate={required()}>
            <SelectInput optionText={(record) => `${record.role}, ${record.name}${record.comment ? (' ('+record.comment+')') : ''}`} />
        </ReferenceInput>
    </Filter>
);

const ResultBulkActionButtons = props => (
    <Fragment>
        <ExportButton {...props}    />
    </Fragment>
);

const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         filterValues,
                         permanentFilter,
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         showCreate,
                         ...rest
                     }: any) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {showCreate ? <CreateButton basePath={basePath}/> : null}
        <ExportButton disabled={total === 0}
                      resource={resource}
                      sort={currentSort}
                      filter={{ ...filterValues, ...permanentFilter }}
                      total={total}
                      {...rest}    />

    </TopToolbar>
);

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
    showCreate: true
};
export const ResultList = (props) => (
    <List {...props} title="Уведомление" filters={<RequestNotifyFilter/>}
          actions={<ListActions showCreate={false}/>}
          bulkActionButtons={<ResultBulkActionButtons/>}
          sort={{ field: 'id', order: 'DESC' }}
          pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}></Pagination>} empty={<EmptyList title={'Результаты отсутствуют'}/>}>
        <Datagrid>
            <IdShowButtonField label={'Id'}/>
            <TextField source="request.id" label={'ID запроса'}/>
            <DateField source={'updatedAt'} label={'Дата проверки'} showTime/>
            <DateField source={'date'} label={'Дата уведомления'} showTime/>
            <FunctionField label="Тип уведомления" render={record => `${feedTypesMap[record.type] || record.type}`}/>
            <FunctionField label="Статус уведомления" render={record => `${feedStatusesMap[record.status] || record.status}`}/>
            <TextField source="extId" label={'Внешний идентификатор уведомления'}/>
            <NumberField source="innerId" label={'Внутренний идентификатор уведомления'}/>
            <TextField source="title" label={'Заголовок уведомления'}/>
            <TextField source="subTitle" label={'Подзаголовок уведомления'}/>
            <TextField source="text" label={'Текст уведомления'}/>
            <BooleanField source="answerHasFiles" label={'Есть вложенные файлы'}/>
            <NumberField source="ownerId" label={'ID владельца'}/>
        </Datagrid>
    </List>
);

const TitleShow = ({ record }: any) => {
    return (
        <span>
      Результат № {record.id}
    </span>
    );
};

export const ResultShow = (props) => (
    <Show {...props} title={<TitleShow/>}>
        <SimpleShowLayout>
            <TextField source="id" label={'ID'}/>
            <TextField source="request.id" label={'ID запроса'}/>
            <DateField source={'updatedAt'} label={'Дата проверки'} showTime/>
            <DateField source={'date'} label={'Дата уведомления'} showTime/>
            <FunctionField label="Тип уведомления" render={record => `${feedTypesMap[record.type] || record.type}`}/>
            <FunctionField label="Статус уведомления" render={record => `${feedStatusesMap[record.status] || record.status}`}/>
            <TextField source="extId" label={'Внешний идентификатор уведомления'}/>
            <NumberField source="innerId" label={'Внутренний идентификатор уведомления'}/>
            <TextField source="title" label={'Заголовок уведомления'}/>
            <TextField source="subTitle" label={'Подзаголовок уведомления'}/>
            <TextField source="text" label={'Текст уведомления'}/>
            <TextField source="answerLink" label={'Ссылка на уведомление'}/>
            <FsspAnswerFilesField source={'files'} label={'Вложения (Файлы)'}/>
            <FsspAnswerHtmlField source={'answerHTML'} label={'HTML Ответ'}/>
            <FsspAnswerField source={'answer'} label={'JSON Ответ'}/>
            <FsspAnswerField source={'history'} label={'История ответов ЕПГУ'}/>
            <NumberField source="ownerId" label={'ID владельца'}/>
        </SimpleShowLayout>

    </Show>
);

