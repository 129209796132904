import React, { FC, Fragment, useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  PropTypes as MuiPropTypes,
} from '@material-ui/core';
import { useDataProvider, useNotify } from 'ra-core';
import { FormWithRedirect, } from 'ra-core';
import DownloadIcon from '@material-ui/icons/GetApp';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  FormInput,
  SaveButton,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";
//import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  gridItem: {
    width: '50%'
  }
}))

interface Props {
  basePath,
  resource,
  show,
  total,
  filter?: any,
  sort?: { field: string, order: string },
  handleClose,
  selectedIds?: any[]
}


const ExportModal: FC<Props> = ({
                                                  basePath,
                                                  resource,
                                                  show,
  total,
  sort,
  filter,
                                  selectedIds,
                                              handleClose,
                                                  ...rest
                                                }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  //const history = useHistory();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const handleCloseClick = () => {
    handleClose();
  };


  const handleSubmit = (values) => {

    setSaving(true);
    dataProvider
      .getList(`export/result/create`, {
        sort,
        filter: {   ...(selectedIds && selectedIds.length > 0 ? { 'id||$in': selectedIds.join(',')}  : filter ), ...(values.exportHtml ? {exportHtml: true} : {}) },
        pagination: { page: 1, perPage: 100000 },
      })
      .then(
        ({ data }) => {
          console.log("get data", data)
          const link = data as any;

          setSaving(false);
          handleClose();
          window.location.href = '/export'
        }
      )
      .catch(error => {
        console.error(error);
        setSaving(false);
        notify('ra.notification.http_error', 'warning');
      });

  }


  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label="Экспорт"
    >
      <DialogTitle>Экспорт</DialogTitle>
      <FormWithRedirect save={handleSubmit} version={1000} saving={false} redirect={''} onSubmit={null} render={ ({handleSubmit}) => (<Fragment>
        <DialogContent>
          {Children.map(
            [
            ],
            input =>
              input && (
                <FormInput
                  basePath={basePath}
                  input={input}
                  resource={resource}
                />
              )
          )}
          <Fragment>
            <Box  p={2}><Typography>Количество экспортируемых строк: <b>{selectedIds && selectedIds.length > 0 ? selectedIds.length : total}</b></Typography>
            </Box>
          </Fragment>

          {total > 1000 && total <= 10000 && <Fragment> <Box m={2} /><Box bgcolor="warning.main" color="warning.contrastText" p={2}><Typography>Внимание! Количество экспортируемых строк больше 1000</Typography></Box></Fragment>}
          {total  > 10000 && <Fragment> <Box m={2} /><Box bgcolor="error.main" color="error.contrastText" p={2}><Typography>Внимание! Количество экспортируемых строк больше 10 000</Typography></Box></Fragment>}

        </DialogContent>
        <DialogActions>
          <SaveButton
            handleSubmitWithRedirect={
              handleSubmit
            }
            label={'Экспорт'}
            icon={<DownloadIcon/>}
            saving={saving}

          />
          <Button label="ra.action.cancel" onClick={handleClose}>
            <IconCancel />
          </Button>
        </DialogActions>
        </Fragment>)}/>
    </Dialog>
);
}


ExportModal.propTypes = {
  basePath: PropTypes.string,
  show: PropTypes.bool,
  total: PropTypes.number,
  handleClose: PropTypes.func,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  filter: PropTypes.object,
  selectedIds: PropTypes.array
};

export default ExportModal;
