import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const SARoleField = ({
                          source, record = {}, labels = {
    'Частное лицо': 'Частное лицо',
    'Руководитель': 'Руководитель',
    'Предприниматель': 'Предприниматель',
    'Сотрудник': 'Сотрудник с ЭД',
  }, colors = {
    'Частное лицо': 'success',
    'Сотрудник': 'warning',
    'Предприниматель': 'error',
    'Руководитель': 'error',
  }
                        }) =>
  <Typography variant={'body2'} color={colors[record[source]] || ''}>{labels[record[source]] || ''}</Typography>;

SARoleField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default SARoleField;
