import * as React from 'react';
import { useContext, useState, FunctionComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
    ExporterContext,
} from 'ra-core';
import { ButtonProps } from '@material-ui/core/Button';
import { Button } from 'react-admin'
import DownloadButton from "./DownloadButton";
import ResumeModal from "./ResumeModal";
import PauseModal from "./PauseModal";

const PauseButton: FunctionComponent<PauseButtonProps> = (props) => {
    const {
        basePath = '',
        record
    } = props
    const exporterFromContext = useContext(ExporterContext);

    const [showDialogPause, setShowDialogPause] = useState(false);
    const [showDialogResume, setShowDialogResume] = useState(false);
    const [showDialogRemove, setShowDialogRemove] = useState(false);


    const handleClose = () => {
        setShowDialogPause(false);
        setShowDialogResume(false);
    }
    const handleRemoveClick = () => {
        setShowDialogRemove(true);
    }
    const handlePauseClick = () => {
        setShowDialogPause(true);
    }

    const handleResumeClick = () => {
        setShowDialogResume(true);
    }


    return (
        <Fragment>
            {record.status === 'created' && <Button
              onClick={handleRemoveClick}
              label={'Отменить'}
            >
              <DeleteForeverIcon/>
            </Button>}
            {record.status === 'in_progress' && <Button
              onClick={handlePauseClick}
              label={'Пауза'}
            >
              <PauseIcon/>
            </Button>}
            {record.status === 'canceled' && <Button
          onClick={handleResumeClick}
          label={'Продолжить'}
        >
          <PlayArrowIcon/>
        </Button>}
            {record.status === 'completed' && <DownloadButton {...props}/>}
            <PauseModal show={showDialogPause} basePath={basePath} record={record} handleClose={handleClose}/>
            <ResumeModal show={showDialogResume} basePath={basePath} record={record} handleClose={handleClose}/>
        </Fragment>
    );
};

interface Props {
    basePath?
    record?
}

export type PauseButtonProps = Props & ButtonProps;

PauseButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.any,
};

export default PauseButton;
