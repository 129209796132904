import React, { useState } from 'react';
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  RadioButtonGroupInput,
  TextInput,
  PasswordInput,
  FileInput,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  FileField,
  FunctionField,
  FormDataConsumer,
  useNotify
} from 'react-admin';
//import ExpansionPanel from "@material-ui/core/ExpansionPanel"
//import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
//import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Typography, Grid} from '@material-ui/core';
import ListActions from "../../components/ListActions";
import EmptyList from "../../components/EmptyList";
//import { useState } from 'react';
//import { Visibility, VisibilityOff } from '@material-ui/icons';
import SARoleField from '../../components/SARoleField';

const SenderAccountFiler = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'id'} variant={'outlined'}/>
    <TextInput source="name||$contL" label={'Название'} variant={'outlined'} alwaysOn/>
    <TextInput source="comment||$contL" label={'Комментарий'} variant={'outlined'} alwaysOn/>
  </Filter>
);

export const SenderAccountList = (props) => (
  <List {...props} title="Аккаунт ЕПГУ" filters={<SenderAccountFiler />} actions={<ListActions/>}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false} empty={<EmptyList title={'Аккаунты ЕПГУ отсутствуют'} description={'Добавить аккаунт отправителя. Аккаунт ЕПГУ это логин или пароль от котрого будет происходить дальнейшая отправка запросов'} buttonText={'Добавить'}/>}>

  <Datagrid rowClick={'edit'}>
      <TextField source="id" />
      <TextField source="name" label={'Название'}/>
      <SARoleField source="role" label={'Роль'}/>
      <TextField source="comment" label={'Комментарий'}/>
      <FunctionField label="Логин"
                    render={record => (record['type'] === 'snils' ? `ЭП ${/*record.login?.match(/[0-9]+_cert@([^$]+)/)?.pop() ||*/ record.login}` : record.login)}/>
      <DateField source={'createdAt'} label={'Создан'} showTime/>
    </Datagrid>
  </List>
);

const TitleEdit = ({ record } : any) => {
  return (
    <span>
      Аккаунт ЕПГУ: {record.name}
    </span>
  );
};

const SenderAccountForm = (props) => {
  const [expanded, setExpanded] = useState(false);
  //const { permissions } = usePermissions();
  const {record,basePath} = props;
  //if (record?.type === 'snils' && record.login?.length)
  //  record.file = {url: `${process.env.REACT_APP_API_URL || 'http://localhost:3000'}${basePath}/cert/${record.id}/${record.login}.zip`, name: `${record.login}.zip`}
  return (
  <SimpleForm {...props} redirect={'list'}>
    <RadioButtonGroupInput
      source="type"
      variant={'outlined'}
      label={'Метод входа'}
      fullWidth={true}
      validate={required()}
      choices={[
        { id: 'login', name: 'Логин - пароль' },
        { id: 'snils', name: 'Электронная подпись (ЭП)' },
      ]}
    />
    <FormDataConsumer fullWidth={true} variant={'outlined'}> 
      {({
        formData,
        ...rest
      }) => formData['type'] === 'snils' ? (
        <FileInput source="file" multiple={false} label="Файл архива ЭП" accept={'.zip'} placeholder={<p>Перетащите файл или нажмите</p>} validate={required()}>
          <FileField source="url" title="name" />
        </FileInput>
      ) : (
        <TextInput source="login" label={'Логин ЕПГУ'} variant={'outlined'} fullWidth={true} validate={required()} />
      )}
    </FormDataConsumer>
    <TextInput source="name" label={'Фамилия И.О. или Название для ЮЛ'} variant={'outlined'} fullWidth={true} validate={required()} />
    <TextInput source="comment" label={'Комментарий'} variant={'outlined'} fullWidth={true} />
    <PasswordInput source="password" label={'Пароль ЕПГУ / Пароль для ЭП'} variant={'outlined'} fullWidth={true} validate={record.id ? null : required()} />
    <SelectInput
      source="role"
      variant={'outlined'}
      label={'Роль'}
      fullWidth={true}
      validate={required()}
      choices={[
        { id: 'Частное лицо', name: 'Частное лицо' },
        { id: 'Сотрудник', name: 'Сотрудник с ЭД' },
        { id: 'Предприниматель', name: 'Предприниматель' },
        { id: 'Руководитель', name: 'Руководитель' },
      ]}
    />
    <ExpansionPanel id="panel-anomaly-act" expanded={expanded} style={{width: '100%'}}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
        <Typography>Вопросы проверки аномальной активности</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container direction="row" alignItems="flex-start">
          <TextInput source="homeNumAddr" label={'Номер дома адреса регистрации'} variant={'outlined'} fullWidth={true} />
          <TextInput source="homeNumAddrL" label={'номер дома адреса проживания'} variant={'outlined'} fullWidth={true} />
          <TextInput source="dateAndMonthBirth" label={'День и месяц даты рождения (ДД.ММ)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="monthAndYearBirth" label={'Месяц и год вашего рождения (ММ.ГГГГ)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="yearIssueNationalPassp" label={'год выдачи загран. паспорта (ГГГГ)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="passportIssueYear" label={'Год выдачи российского паспорта (ГГГГ)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="passportFirstTwoDigits" label={'Первые 2 цифры номера паспорта (xx)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="passportLastTwoDigits" label={'Последние 2 цифры номера паспорта (xx)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="yearIssueDriverLic" label={'Год выдачи водит. удостоверения'} variant={'outlined'} fullWidth={true} />
          <TextInput source="lastDigDriverLic" label={'Поcледние 4 цифры водит. удостоверения (xxxx)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="gosRegNumTs" label={'Государственный рег. номер транспортного средства (AxxxAAxxx)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="dateAndMonthBirthChildren" label={'Дата и месяц рождения вашего ребенка (ДД.ММ)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="nameChildren" label={'Имя вашего ребенка'} variant={'outlined'} fullWidth={true} />
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </SimpleForm>
)}

export const SenderAccountEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SenderAccountForm />
  </Edit>
);

export const SenderAccounCreate = (props) => {
  const notify = useNotify();
  const onError = (error) => {
    notify(`Не удается создать аккаунт отправителя: ${error.message}`, { type: 'warning' });
  };
  return (
    <Create title="Создать аккаунт отправителя" {...props} onFailure={onError}>
      <SenderAccountForm />
    </Create>
  );
}


